import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link } from "react-router-dom";

export interface IRequestApiAccessProps {
  userId: number;
}

export default function RequestApiAccess({ userId }: IRequestApiAccessProps) {
  return (
    <Card className="max-w-2xl">
      <CardHeader>
        <CardTitle>Accéder à l'API</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription className="leading-normal">
          Utilisez l'API pour récupérer le contenu de vos widgets et intégrer
          nos contenus de manière plus native dans vos sites web ou applications
          mobiles.
        </CardDescription>
      </CardContent>
      <CardFooter className="justify-end">
        <Link
          to={`mailto:axel@konnectz.io;emmanuel@timenjoy.fr?subject=${encodeURIComponent("Konnectz - Demande d'accès à l'api")}&body=${encodeURIComponent(`Comment souhaitez-vous utiliser l'api ?\n\n\nRéférence client: ${userId}\n\n`)}`}
        >
          <Button>Demander l'accès</Button>
        </Link>
      </CardFooter>
    </Card>
  );
}
