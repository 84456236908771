import { ActionsMenu } from "@/components/actions-menu";
import * as React from "react";
import CompanyActions from "./company-actions";
import Awaited from "@/components/awaited";
import { listCompanies } from "@/api/companies";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@/components/ui/table";
import { MoreHorizontal } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { extractEnabledAdvertisementTypes } from "@/api/companies";
import { formatDate } from "@/lib/date";
import { SUBSCRIPTION_TYPE } from "@/api/definitions";
import { ContentForType } from "@/api/advertisements";
import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { ColumnsState } from "@/hooks/useColumns";
import { CompaniesTableColumnName } from "./columns";

export interface ICompanyRowProps {
  company: Awaited<ReturnType<typeof listCompanies>>[number];
  columnState: ColumnsState<CompaniesTableColumnName>;
}

export default function CompanyRow({ company, columnState }: ICompanyRowProps) {
  return (
    <ActionsMenu Actions={CompanyActions} data={{ company }}>
      <TableRow key={company.id}>
        <TableCell
          className="font-light source-code-pro-font"
          hidden={!columnState.id.visible}
        >
          #{company.id}
        </TableCell>
        <TableCell hidden={!columnState.logo.visible}></TableCell>
        <TableCell hidden={!columnState.name.visible}>{company.name}</TableCell>
        <TableCell hidden={!columnState.types.visible} className="space-x-2">
          {extractEnabledAdvertisementTypes(company).map((type) => (
            <span key={type}>{ContentForType[type].miniLabel}</span>
          ))}
          {company.apiEnabled ? <span>API</span> : null}
        </TableCell>
        <TableCell hidden={!columnState.subscription.visible}>
          {company.subscription === SUBSCRIPTION_TYPE.PREMIUM ? (
            <Badge
              variant="outline"
              className="bg-accent text-accent-foreground border-accent"
            >
              Premium
            </Badge>
          ) : company.subscription === SUBSCRIPTION_TYPE.STANDARD ? (
            <Badge variant="default">Standard</Badge>
          ) : (
            <Badge variant="secondary">Freemium</Badge>
          )}
          {/* <Badge
            variant="outline"
            className="bg-accent text-accent-foreground border-accent"
          >
            Administrateur
          </Badge> */}
        </TableCell>
        <TableCell hidden={!columnState.widgets.visible}>
          {company.widgetCount}
        </TableCell>
        <TableCell hidden={!columnState.users.visible}>
          {company.userCount}
        </TableCell>
        <TableCell
          hidden={!columnState.created_at.visible}
          className="whitespace-nowrap"
        >
          {formatDate(company.createdAt, true)}
        </TableCell>
        <TableCell className="flex justify-end">
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <MoreHorizontal />
            </Button>
          </DropdownMenuTrigger>
        </TableCell>
      </TableRow>
    </ActionsMenu>
  );
}
