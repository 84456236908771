import { TabLayoutBreadcrumb, TabLayoutContent } from "@/components/tab-layout";
import { Button } from "@/components/ui/button";
import { useFetcher, useLoaderData } from "react-router-dom";
import { IApiSettingsLoaderData } from "./loader";
import RequestApiAccess from "./blocks/request-access";
import RefPortal from "@/components/ref-portal";
import { MoreHorizontal, Plus } from "lucide-react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import Awaited from "@/components/awaited";
import dayjs from "dayjs";
import { useCreateApiKeyModal } from "@/modals/create-api-key";
import { ActionsMenu } from "@/components/actions-menu";
import ApiKeysActions from "./blocks/api-keys-actions";
import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";

export interface IApiSettingsPageProps {}

export default function ApiSettingsPage(props: IApiSettingsPageProps) {
  const data = useLoaderData() as IApiSettingsLoaderData;
  const createApiKeyModale = useCreateApiKeyModal();
  if (!data.isApiEnabled)
    return (
      <TabLayoutContent>
        <TabLayoutBreadcrumb>Développeurs</TabLayoutBreadcrumb>
        <RequestApiAccess userId={data.userId} />
      </TabLayoutContent>
    );

  return (
    <TabLayoutContent>
      <TabLayoutBreadcrumb>Sites</TabLayoutBreadcrumb>
      <RefPortal selector="#tab-layout-head-outlet">
        <Button onClick={createApiKeyModale.openModal}>
          <Plus className="h-4 w-4 inline mr-2" />
          Créer une clé d'api
        </Button>
      </RefPortal>
      <ScrollArea className="border border-border rounded-md">
        <Table>
          <Awaited
            resolve={data.apiKeys}
            render={(apiKeys) => {
              if (!apiKeys.length)
                return (
                  <TableCaption className="mb-4">
                    Vous n'avez aucune clé d'api.
                  </TableCaption>
                );
              return null;
            }}
          />
          <TableHeader>
            <TableRow>
              <TableHead className="w-20">#</TableHead>
              <TableHead>Nom de la clé</TableHead>
              <TableHead className="w-64">Dernière utilisation</TableHead>
              <TableHead className="w-16">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Awaited
              resolve={data.apiKeys}
              render={(apiKeys) =>
                apiKeys.map((apiKey) => (
                  <ActionsMenu
                    Actions={ApiKeysActions}
                    key={apiKey.id}
                    data={apiKey}
                  >
                    <TableRow>
                      <TableCell>#{apiKey.id.slice(0, 4)}</TableCell>
                      <TableCell>{apiKey.name ?? "Sans nom"}</TableCell>
                      <TableCell className="w-fit">
                        {apiKey.lastUsage ? (
                          dayjs(apiKey.lastUsage).fromNow()
                        ) : (
                          <span className="text-sm text-muted-foreground italic">
                            Jamais
                          </span>
                        )}
                      </TableCell>
                      <TableCell
                        className="flex justify-end h-14"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DropdownMenuTrigger asChild>
                          <Button variant="outline" size="icon">
                            <MoreHorizontal />
                          </Button>
                        </DropdownMenuTrigger>
                      </TableCell>
                    </TableRow>
                  </ActionsMenu>
                ))
              }
            />
          </TableBody>
        </Table>
      </ScrollArea>
    </TabLayoutContent>
  );
}
