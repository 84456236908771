import { cache } from "@/lib/cache";
import { API_BASE, logErrorResponse } from "./common";
import {
  ApiKeysSchema,
  CreateApiKeysDTO,
  UpdateApiKeysDTO,
} from "./definitions";

export const listApiKeys = cache(async function listApiKeys(
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/api-keys`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return ApiKeysSchema.array().parse(data);
}, "1 hour");

export async function createApiKeys(payload: CreateApiKeysDTO) {
  const response = await fetch(`${API_BASE}/api-keys`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  listApiKeys.invalidate();
  return ApiKeysSchema.parse(data);
}
export async function updateApiKeys(id: string, payload: UpdateApiKeysDTO) {
  const response = await fetch(`${API_BASE}/api-keys/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  listApiKeys.invalidate();
  return ApiKeysSchema.parse(data);
}
export async function deleteApiKeys(id: string) {
  const response = await fetch(`${API_BASE}/api-keys/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) throw await logErrorResponse(response);
  listApiKeys.invalidate();
  return true;
}
