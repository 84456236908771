import { listApiKeys } from "@/api/api_keys";
import { getCurrentUser } from "@/api/authentication";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IApiSettingsLoaderData {
  userId: number;
  isApiEnabled: boolean;
  apiKeys: ReturnType<typeof listApiKeys>;
}

export async function ApiSettingsLoader({ request }: LoaderFunctionArgs) {
  const session = await getCurrentUser();
  if (!session) throw redirect("/");
  const isApiEnabled = session.company?.apiEnabled ?? false;
  return {
    userId: session.user.id,
    isApiEnabled,
    apiKeys: isApiEnabled ? listApiKeys(request.signal) : Promise.resolve([]),
  } satisfies IApiSettingsLoaderData;
}
