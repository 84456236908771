import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FetcherWithComponents, Form, useFetcher } from "react-router-dom";
import { ApiKeysDTO, CompaniesDTO } from "@/api/definitions";
import FormState from "@/components/form-state";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { CopyIcon } from "lucide-react";
import { copyText } from "@/lib/copy";

export interface ICreateApiKeyModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
}

function ShowApiKey({ apiKey }: { apiKey: string }) {
  return (
    <DialogContent className="bg-card border-border text-foreground">
      <DialogHeader>
        <DialogTitle>Voici votre clé d'api</DialogTitle>
      </DialogHeader>
      <div className="space-y-2">
        <Label className="font-medium text-muted-foreground">
          Gardez-la précieusement, elle ne sera plus visible par la suite.
        </Label>
        <div className="flex items-center gap-2">
          <Input disabled value={apiKey} />
          <Button
            size="icon"
            variant="outline"
            className="shrink-0"
            onClick={() => copyText(apiKey)}
          >
            <CopyIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </DialogContent>
  );
}

function ApiKeyForm({
  fetcher,
}: {
  fetcher: FetcherWithComponents<ApiKeysDTO>;
}) {
  return (
    <DialogContent className="bg-card border-border text-foreground">
      <DialogHeader>
        <DialogTitle>Créer une clé d'api</DialogTitle>
      </DialogHeader>
      <fetcher.Form
        method="POST"
        action="/app/settings/api?index"
        className="space-y-4"
      >
        <div className="space-y-2">
          <Label>Label</Label>
          <Input name="name" />
          <DialogDescription>
            Nommez votre clé d'api pour la différencier des autres.
          </DialogDescription>
        </div>
        {}
        <DialogFooter>
          <Button type="submit">Confirmer</Button>
        </DialogFooter>
      </fetcher.Form>
    </DialogContent>
  );
}

export function CreateApiKeyModal({ closeModal }: ICreateApiKeyModalProps) {
  const fetcher = useFetcher<ApiKeysDTO>();

  return (
    <Dialog open onOpenChange={closeModal}>
      {fetcher.data?.apiKey ? (
        <ShowApiKey apiKey={fetcher.data.apiKey!} />
      ) : (
        <ApiKeyForm fetcher={fetcher} />
      )}
    </Dialog>
  );
}

export const [useCreateApiKeyModal, CreateApiKeyProvider, CreateApiKeyContext] =
  createModalContext(CreateApiKeyModal);
