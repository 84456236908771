import * as React from "react";
import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useFetcher, useFetchers } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useCache } from "@/hooks/use-cache";
import Awaited from "@/components/awaited";
import {
  ADVERTISEMENT_TYPE,
  CreateScheduleDTO,
  ROTATION_STRATEGY,
} from "@/api/definitions";
import dayjs, { displayDates } from "@/lib/date";
import { Loader, Plus } from "lucide-react";
import { ExtractResult } from "@/lib/cache";
import { Skeleton } from "@/components/ui/skeleton";
import { join } from "@/lib/promise";
import { listOwnedWidgets } from "@/api/widgets";
import { getAdvertisementById } from "@/api/advertisements";
import ScheduleFormItem, {
  NewScheduleItem,
} from "@/components/schedule-form-item";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AdvertisementBuilderSchedulesSchema } from "@/pages/app/advertisements/new/[type]/(blocks)/schemas";
import ImageInput from "@/components/image-input";
import { objectToFormData } from "@/lib/query";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import { useSearchState } from "@/hooks/use-search-state";
import { useInterval } from "@/hooks/use-interval";
import { canScheduleOwnedAds, PlanUpgradeError } from "@/api/authorization";
import { useLimitReachedModal } from "./limit-reached";
import { getCurrentUser } from "@/api/authentication";

export interface ICreateWidgetModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
  advertisementId: string;
  defaultWidget?: string;
  noRedirect?: boolean;
}

export interface ICreateScheduleModalContentProps {
  defaultWidget?: string;
  closeModal?(triggerCloseEvent?: boolean): void;
  widgets: ExtractResult<typeof listOwnedWidgets>;
  advertisement: ExtractResult<typeof getAdvertisementById>;
  noRedirect?: boolean;
}

export default function CreateScheduleModalContent({
  defaultWidget,
  widgets,
  advertisement,
  noRedirect,
  closeModal,
}: ICreateScheduleModalContentProps) {
  const advertisementStart =
    advertisement.type !== ADVERTISEMENT_TYPE.EVENT
      ? undefined
      : new Date(
          advertisement.dates.reduce(
            (acc, cur) => Math.min(acc, +cur.from),
            Infinity,
          ),
        );
  const advertisementEnd =
    advertisement.type !== ADVERTISEMENT_TYPE.EVENT
      ? undefined
      : new Date(
          advertisement.dates.reduce((acc, cur) => Math.max(acc, +cur.to), 0),
        );
  const form = useForm({
    mode: "onTouched",
    resolver: zodResolver(AdvertisementBuilderSchedulesSchema),
    defaultValues: {
      schedules: [
        {
          widgetId: defaultWidget ?? widgets.results[0].id,
          // from: dayjs().add(1, "day").toDate(),
          // to: dayjs().add(1, "day").toDate(),
          clic_goal: null,
          impression_goal: null,
          sponsored: true,
        },
      ] as NewScheduleItem[],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "schedules",
  });
  const [openedFields, setOpenedFields] = React.useState<string | undefined>(
    "",
  );
  React.useEffect(() => {
    if (fields.length === 1) return;
    setOpenedFields(fields.at(-1)?.id);
  }, [fields.length]);
  const fetcher = useFetcher();
  React.useEffect(() => {
    if (fetcher.data) closeModal?.();
  }, [fetcher.data]);
  return (
    <>
      <div className="space-x-4 w-full border border-border py-3 px-4 rounded-sm flex items-center">
        <ImageInput
          file={advertisement.main_image}
          className="w-20 h-10 rounded-[3px]"
        />
        <div className="flex flex-col w-full max-w-[200px]">
          <div className="text-sm w-full truncate">{advertisement.title}</div>
          <div className="text-sm text-muted-foreground w-full truncate">
            {advertisement.type === ADVERTISEMENT_TYPE.EVENT &&
              displayDates(advertisementStart, advertisementEnd) + " | "}
            {advertisement.location_name}
          </div>
        </div>
      </div>
      <div className="h-full overflow-y-scroll space-y-2">
        {fields.map((field, index) => {
          const values = form.watch(`schedules.${index}`);
          return (
            <ScheduleFormItem
              key={field.id}
              isOpen={openedFields === field.id}
              setOpen={(newOpen) => setOpenedFields(newOpen ? field.id : "")}
              data={values}
              widgets={widgets.results}
              setData={(val) => {
                form.setValue(`schedules.${index}`, val);
                form.trigger();
              }}
              remove={() => remove(index)}
            />
          );
        })}
      </div>
      <DialogFooter className="enter animation-fast flex items-center gap-4">
        <Button
          onClick={() => {
            let from,
              to = (from = dayjs().add(1, "day").toDate()),
              widgetId = widgets.results[0].id;
            if (fields.length) {
              const lastRow = form.watch(`schedules.${fields.length - 1}`);
              from = lastRow.from;
              to = lastRow.to;
              widgetId = lastRow.widgetId;
            }
            append({
              widgetId: widgetId,
              from: from,
              to: to,
              impression_goal: null,
              clic_goal: null,
              sponsored: true,
            });
          }}
          type="button"
          variant="secondary"
        >
          <Plus className="w-4 h-4 mr-2" />
          Ajouter
        </Button>
        <Button
          type="submit"
          onClick={() => {
            fetcher.submit(
              objectToFormData({
                noRedirect,
                schedules: JSON.stringify(
                  form.getValues().schedules.map(
                    (s) =>
                      ({
                        ...s,
                        rotation_strategy: s.sponsored
                          ? ROTATION_STRATEGY.ROTATE
                          : ROTATION_STRATEGY.FILLER,
                        widget_id: s.widgetId,
                        advertisement_version_id: advertisement.id,
                      }) satisfies CreateScheduleDTO,
                  ),
                ),
              }),
              {
                action: "/app/widgets/:widgetId/schedules?index",
                method: "POST",
                encType: "multipart/form-data",
              },
            );
          }}
        >
          Confirmer
        </Button>
      </DialogFooter>
    </>
  );
}

export function CreateScheduleModal({
  closeModal,
  defaultWidget,
  advertisementId,
  noRedirect,
}: ICreateWidgetModalProps) {
  const advertisementQuery = useCache(getAdvertisementById, advertisementId);
  const widgetsQuery = useCache(listOwnedWidgets);
  const fetchers = useFetchers();
  const navigation = useRouteNavigation();
  const [_, defaultWidgetFromSearchParams] = useSearchState<string | undefined>(
    { key: "defaultWidget" },
  );
  console.log("loading", fetchers, navigation);
  useInterval(() => {
    console.log("loading", fetchers, navigation);
  }, "1 s");
  const isAuthorized = useCache(canScheduleOwnedAds).catch((e) => e);
  const currentUser = useCache(getCurrentUser);
  const limitReachedModal = useLimitReachedModal();
  return (
    <Dialog open onOpenChange={closeModal} modal={false}>
      <div className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <DialogContent className="min-h-[300px] flex flex-col bg-card border-border text-foreground w-full max-w-2xl">
        <DialogHeader className="space-y-4 enter animation-fast">
          <DialogTitle>Programmer la diffusion</DialogTitle>
          <DialogDescription>
            Programmez votre annonce sur des widgets, plages de date et
            objectifs de performance.
          </DialogDescription>
        </DialogHeader>
        <Awaited
          // loading={
          //   fetchers.reduce((a, b) => (a.state === "idle" ? b : a), {
          //     state: navigation.state,
          //   }).state
          // }
          resolve={join(
            advertisementQuery,
            widgetsQuery,
            currentUser,
            isAuthorized,
          )}
          fallback={
            <>
              <div className="enter animation-fast space-x-4 w-full border border-border py-3 px-4 rounded-sm flex items-center">
                <Skeleton className="w-20 h-10 rounded-[3px]" />
                <div className="flex flex-col gap-1 pt-0.5 justify-between w-full max-w-[200px]">
                  <Skeleton className="w-40 h-3.5" />
                  <Skeleton className="w-40 h-2.5" />
                </div>
              </div>
              <div className="enter animation-fast w-full h-full grow flex items-center justify-center">
                <Loader className="w-6 h-6 text-muted-foreground animate-spin" />
              </div>
            </>
          }
          render={([advertisement, widgets, session, authRes]) => {
            if (!session) return null;
            if (
              authRes instanceof PlanUpgradeError &&
              session.company &&
              advertisement.company_id === session.company.id
            ) {
              limitReachedModal.openModal({ error: authRes });
              closeModal?.();
              return null;
            }
            return (
              <CreateScheduleModalContent
                advertisement={advertisement}
                widgets={widgets}
                noRedirect={noRedirect}
                defaultWidget={defaultWidget || defaultWidgetFromSearchParams}
                closeModal={closeModal}
              />
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export const [
  useCreateScheduleModal,
  CreateScheduleProvider,
  CreateScheduleContext,
] = createModalContext(CreateScheduleModal);
