import { cache } from "@/lib/cache";
import { getCurrentUser } from "./authentication";
import { CompaniesDTO, SUBSCRIPTION_TYPE } from "./definitions";
import { listOwnedWidgets } from "./widgets";
import dayjs from "dayjs";

const pricingLimits = {
  [SUBSCRIPTION_TYPE.FREEMIUM]: {
    widgets: 1,
  },
  [SUBSCRIPTION_TYPE.STANDARD]: {
    widgets: 3,
  },
  [SUBSCRIPTION_TYPE.PREMIUM]: {
    widgets: Infinity,
  },
} as const;

export function isInFreeTrial(company: CompaniesDTO) {
  return dayjs().isBefore(company.trialEnd);
}
export const canCreateWidget = cache(async function canCreateWidget() {
  const session = await getCurrentUser();
  if (!session?.company) return false;
  if (isInFreeTrial(session.company)) return true;
  const sub = session.company.subscription;
  if (sub === SUBSCRIPTION_TYPE.PREMIUM) return true;
  const widgets = await listOwnedWidgets();
  if (widgets.meta.count < pricingLimits[sub].widgets) return true;
  if (sub === SUBSCRIPTION_TYPE.STANDARD)
    throw new UpgradePremiumError(PlanUpgradeReason.WIDGETS);
  throw new UpgradeStandardOrPremiumError(PlanUpgradeReason.WIDGETS);
}, "5 mins");

export const canCreateAds = cache(async () => {
  const session = await getCurrentUser();
  if (!session?.company) return false;
  if (isInFreeTrial(session.company)) return true;
  if (session.company.subscription !== SUBSCRIPTION_TYPE.FREEMIUM) return true;
  throw new UpgradeStandardOrPremiumError(PlanUpgradeReason.ADVERTISEMENTS);
}, "5 mins");

export const canCustomizeWidgets = cache(async () => {
  const session = await getCurrentUser();
  if (!session?.company) return false;
  if (isInFreeTrial(session.company)) return true;
  if (session.company.subscription === SUBSCRIPTION_TYPE.PREMIUM) return true;
  throw new UpgradePremiumError(PlanUpgradeReason.ADVERTISEMENTS);
}, "5 mins");

export const canScheduleOwnedAds = cache(async () => {
  const session = await getCurrentUser();
  if (!session?.company) return false;
  if (isInFreeTrial(session.company)) return true;
  if (session.company.subscription !== SUBSCRIPTION_TYPE.FREEMIUM) return true;
  throw new UpgradeStandardOrPremiumError(PlanUpgradeReason.SCHEDULE);
}, "5 mins");

export const canSeeAnalytics = cache(async () => {
  const session = await getCurrentUser();
  if (!session?.company) return false;
  if (isInFreeTrial(session.company)) return true;
  if (session.company.subscription === SUBSCRIPTION_TYPE.PREMIUM) return true;
  throw new UpgradePremiumError(PlanUpgradeReason.ANALYTICS);
}, "5 mins");

export enum PlanUpgradeReason {
  WIDGETS = "widgets",
  ADVERTISEMENTS = "advertisements",
  SCHEDULE = "schedules",
  ANALYTICS = "analytics",
}
export class PlanUpgradeError extends Error {
  domain: PlanUpgradeReason = PlanUpgradeReason.WIDGETS;
  cta: string = `S'abonner`;
  constructor(message: string) {
    super(message);
    this.name = "PlanUpgradeError";
  }
}
export class UpgradeStandardOrPremiumError extends PlanUpgradeError {
  cta = `S'abonner`;
  constructor(public domain: PlanUpgradeReason) {
    super(
      {
        [PlanUpgradeReason.WIDGETS]:
          "Abonnez-vous pour créer d'autres widgets.",
        [PlanUpgradeReason.ADVERTISEMENTS]:
          "Abonnez-vous pour créer vos propres annonces.",
        [PlanUpgradeReason.SCHEDULE]:
          "Abonnez-vous pour programmer vos annonces.",
        [PlanUpgradeReason.ANALYTICS]:
          "Abonnez-vous pour accéder aux performances de vos annonces et widgets.",
      }[domain],
    );
  }
}
export class UpgradeStandardError extends PlanUpgradeError {
  cta = `Passer à l'abonnement Standard`;
  constructor(public domain: PlanUpgradeReason) {
    super(
      {
        [PlanUpgradeReason.WIDGETS]:
          "Passez au plan Standard pour créer jusqu'à 3 widgets.",
        [PlanUpgradeReason.ADVERTISEMENTS]:
          "Abonnez-vous pour créer vos propres annonces.",
        [PlanUpgradeReason.SCHEDULE]:
          "Abonnez-vous pour programmer vos annonces.",
        [PlanUpgradeReason.ANALYTICS]:
          "Abonnez-vous pour accéder aux performances de vos annonces et widgets.",
      }[domain],
    );
  }
}
export class UpgradePremiumError extends PlanUpgradeError {
  cta = `Passer à l'abonnement Premium`;
  constructor(public domain: PlanUpgradeReason) {
    super(
      {
        [PlanUpgradeReason.WIDGETS]:
          "Passer à l'abonnement Premium pour créer des widgets en illimité.",
        [PlanUpgradeReason.ADVERTISEMENTS]:
          "Abonnez-vous pour créer vos propres annonces.",
        [PlanUpgradeReason.SCHEDULE]:
          "Abonnez-vous pour programmer vos annonces.",
        [PlanUpgradeReason.ANALYTICS]:
          "Abonnez-vous pour accéder aux performances de vos annonces et widgets.",
      }[domain],
    );
  }
}
