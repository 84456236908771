import { canCustomizeWidgets } from "@/api/authorization";
import { ExtractResult } from "@/lib/cache";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IAppDesignAuthGuardLoaderData {
  authorization: ExtractResult<typeof canCustomizeWidgets> | Error;
}

export async function AppDesignAuthGuardLoader({}: LoaderFunctionArgs) {
  const authorization = await canCustomizeWidgets().catch((e) => e);
  console.log("authorization", { authorization });
  return { authorization };
}
