import * as z from "@/lib/zod";
import dayjs from "@/lib/date";
import { BUCKET_URL } from "./common";

export const SafeUsersSchema = z.object({
  id: z.coerce.number(),
  email: z.string(),
  firstname: z.string().nullish(),
  lastname: z.string().nullish(),
  canLogin: z.bool(),
  companyId: z.coerce.number().nullish(),
  policyId: z.coerce.number().nullish(),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
});

export interface SafeUsersDTO extends z.infer<typeof SafeUsersSchema> {}

export enum SUBSCRIPTION_TYPE {
  PREMIUM = "premium",
  STANDARD = "standard",
  FREEMIUM = "freemium",
}

export const CompaniesSchema = z.object({
  id: z.coerce.number(),
  name: z.string(),
  eventEnabled: z.bool(),
  immoEnabled: z.bool(),
  jobEnabled: z.bool(),
  apiEnabled: z.bool(),
  trialEnd: z.date().or(z.dateString()).nullable(),
  authorizedOrigins: z.string().array(),
  subscription: z.nativeEnum(SUBSCRIPTION_TYPE),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
});
export interface CompaniesDTO extends z.infer<typeof CompaniesSchema> {}

export const SessionSchema = z.object({
  user: SafeUsersSchema,
  company: CompaniesSchema.nullish(),
  isAdmin: z.bool().optional(),
});
export interface SessionDTO extends z.infer<typeof SessionSchema> {}

export enum ADVERTISEMENT_TYPE {
  EVENT = "event",
  REAL_ESTATE = "real_estate",
  JOB = "job",
}
export const AdvertisementTypeSchema = z.nativeEnum(ADVERTISEMENT_TYPE);

export function paginationSchema(max = 100) {
  return z
    .object({
      limit: z.coerce.number().int().min(1).max(max),
      offset: z.coerce.number().int().min(0),
    })
    .partial();
}

export enum ADVERTISEMENT_SORTING {
  CLOSEST = "dates:asc",
  FURTHEST = "dates:desc",
  UPDATE_ASC = "updated_at:asc",
  UPDATE_DESC = "updated_at:desc",
  CREATION_ASC = "created_at:asc",
  CREATION_DESC = "created_at:desc",
}

export const BaseAdvertisementFiltersSchema = z.object({
  type: z.nativeEnum(ADVERTISEMENT_TYPE),
  ids: z.oneOrMore(z.string()).optional(),
  query: z.string().optional(),
  zoneId: z.oneOrMore(z.coerce.number()).optional(),
  lat: z.coerce.number().optional(),
  lon: z.coerce.number().optional(),
  company_id: z.coerce.number().optional(),
  is_main_advertisement: z.bool().optional(),
  archived: z.bool().optional(),
  all_visible: z.bool().optional(),
  sort: z.nativeEnum(ADVERTISEMENT_SORTING).optional(),
});
export const EventFiltersSchema = z.object({
  from: z.dateString().optional(),
  to: z.dateString().optional(),
  ongoing: z.bool().optional(),
  categories: z.oneOrMore(z.coerce.number()).optional(),
});
export const RealEstateFiltersSchema = z.object({
  surface: z.string().optional(),
  rooms: z.string().optional(),
  price: z.string().optional(),
});
export const JobAdvertisementFiltersSchema = z.object({
  company_name: z.string().optional(),
});

export const ListAdvertisementsFiltersSchema =
  BaseAdvertisementFiltersSchema.and(EventFiltersSchema)
    .and(RealEstateFiltersSchema)
    .and(JobAdvertisementFiltersSchema)
    .and(paginationSchema(200));

export interface ListAdvertisementsFiltersDTO
  extends z.infer<typeof ListAdvertisementsFiltersSchema> {}

export const AdvertisementFluxSchema = BaseAdvertisementFiltersSchema.pick({
  type: true,
  zoneId: true,
}).merge(EventFiltersSchema.pick({ categories: true }));

export interface AdvertisementFluxDTO
  extends z.infer<typeof AdvertisementFluxSchema> {}

export enum WIDGET_TYPE {
  CAROUSEL = "carousel",
  PAGEVIEW = "pageview",
}

export const IconForWidgetType: Record<WIDGET_TYPE, string> = {
  [WIDGET_TYPE.CAROUSEL]: "/img/carousel-icon.svg",
  [WIDGET_TYPE.PAGEVIEW]: "/img/pageview-icon.svg",
};

export const WidgetsSchema = z.object({
  id: z.string(),
  company_id: z.coerce.number().nullable(),
  name: z.string(),
  public_key: z.string(),
  source: z.string().nullable(),
  source_id: z.string().nullable(),
  rotation_active: z.bool(),
  exposed: z.bool(),
  include_owned_advertisements: z.bool(),
  advertisements_flux: AdvertisementFluxSchema.array()
    .nullish()
    .transform((t) => t ?? []),
  type: z.nativeEnum(WIDGET_TYPE),
  customization: z.coerce.number().nullable(),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
});

export interface WidgetsDTO extends z.infer<typeof WidgetsSchema> {}

export function ListResponseSchema<
  T extends z.ZodTypeAny,
  Z extends z.ZodTypeAny = z.ZodTypeAny,
>(dataSchema: T, filtersSchema?: Z) {
  return z.object({
    filters: filtersSchema ?? z.any(),
    meta: z.object({
      count: z.coerce.number(),
    }),
    results: dataSchema.array(),
  });
}
export interface ListResponseDTO<T extends any, F extends any = any>
  extends Record<string, unknown> {
  filters?: F;
  meta: {
    count: number;
  };
  results: T[];
}

export const AdvertisementAnalyticsFiltersSchema = z
  .object({
    from: z
      .dateString()
      .optional()
      .default(dayjs().subtract(29, "d").startOf("d").toISOString()),
    to: z.dateString().optional().default(dayjs().endOf("d").toISOString()),
    widgetId: z
      .oneOrMore(z.string())
      .transform((arr) => [...new Set(arr)])
      .optional(),
  })
  .refine((d) => {
    return dayjs(d.to).diff(d.from, "hour") >= 1;
  });
export interface AdvertisementAnalyticsFiltersDTO
  extends z.infer<typeof AdvertisementAnalyticsFiltersSchema> {}

export const WidgetAnalyticsFiltersSchema = z
  .object({
    from: z
      .dateString()
      .optional()
      .default(dayjs().subtract(29, "d").startOf("d").toISOString()),
    to: z.dateString().optional().default(dayjs().endOf("d").toISOString()),
  })
  .refine((d) => {
    return dayjs(d.to).diff(d.from, "hour") >= 1;
  });
export interface WidgetAnalyticsFiltersDTO
  extends z.infer<typeof WidgetAnalyticsFiltersSchema> {}

export const WidgetAnalyticsSummarySchema = z.object({
  period: z.tuple([z.dateString(), z.dateString()]),
  timeSerie: z
    .object({
      impressions: z.coerce.number(),
      clics: z.coerce.number(),
      interactions: z.coerce.number(),
      hour: z.dateString(),
    })
    .array(),
  impression_per_origin: z
    .object({
      impressions: z.coerce.number(),
      origin: z.string().url(),
    })
    .array(),
  advertisements: z
    .object({
      impressions: z.coerce.number(),
      clics: z.coerce.number(),
      advertisement: z.string(),
    })
    .array(),
  compute_time: z.coerce.number(),
});

export interface WidgetAnalyticsSummaryDTO
  extends z.infer<typeof WidgetAnalyticsSummarySchema> {}

export const AdvertisementAnalyticsSchema = z.object({
  period: z.tuple([z.dateString(), z.dateString()]),
  data: z
    .object({
      impressions: z.coerce.number(),
      clics: z.coerce.number(),
      hour: z.dateString(),
    })
    .array(),
  compute_time: z.coerce.number(),
});
export interface AdvertisementAnalyticsDTO
  extends z.infer<typeof AdvertisementAnalyticsSchema> {}

export const BlackListsSchema = z.object({
  widget_id: z.string(),
  advertisement_version_id: z.string(),
});

export interface BlackListsDTO extends z.infer<typeof BlackListsSchema> {}

export const AdvertisementVersionSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullish(),
  main_image: z
    .string()
    .url()
    .or(
      z
        .string()
        .refine((s) => {
          try {
            new URL(s);
            return false;
          } catch (e) {
            return true;
          }
        })
        .transform((s) => `${BUCKET_URL}/${s}`),
    ),
  url: z.string(),
  type: z.nativeEnum(ADVERTISEMENT_TYPE),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
  is_main_version: z.bool(),
  square_image: z
    .string()
    .url()
    .or(
      z
        .string()
        .refine((s) => {
          try {
            new URL(s);
            return false;
          } catch (e) {
            return true;
          }
        })
        .transform((s) => `${BUCKET_URL}/${s}`),
    )
    .nullish(),
  landscape_image: z
    .string()
    .url()
    .or(
      z
        .string()
        .refine((s) => {
          try {
            new URL(s);
            return false;
          } catch (e) {
            return true;
          }
        })
        .transform((s) => `${BUCKET_URL}/${s}`),
    )
    .nullish(),
  portrait_image: z
    .string()
    .url()
    .or(
      z
        .string()
        .refine((s) => {
          try {
            new URL(s);
            return false;
          } catch (e) {
            return true;
          }
        })
        .transform((s) => `${BUCKET_URL}/${s}`),
    )
    .nullish(),
  company_id: z.coerce.number().nullish(),
  crawler: z.string().nullish(),
  crawled_id: z.string().nullish(),
  advertisement_id: z.coerce.number(),
});
export interface AdvertisementVersionDTO
  extends z.infer<typeof AdvertisementVersionSchema> {}

export enum ROTATION_STRATEGY {
  ROTATE = "rotate",
  FILLER = "filler",
  FIXED = "fixed",
}

export const SchedulesSchema = z.object({
  id: z.coerce.number(),
  advertisement_version_id: z.string(),
  widget_id: z.string(),
  from: z.dateString().or(z.date()),
  to: z.dateString().or(z.date()),
  enabled: z.bool(),
  order: z.coerce.number(),
  rotation_strategy: z.nativeEnum(ROTATION_STRATEGY),
  impression_goal: z.coerce.number().nullable(),
  clic_goal: z.coerce.number().nullable(),
  createdAt: z.dateString(),
  updatedAt: z.dateString(),
});
export interface SchedulesDTO extends z.infer<typeof SchedulesSchema> {}

export const CategoriesSchema = z.object({
  id: z.coerce.number(),
  name: z.string(),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
});
export interface CategoriesDTO extends z.infer<typeof CategoriesSchema> {}

export enum ZONE_LEVEL {
  PAYS = "pays",
  REGION = "region",
  DEPARTEMENT = "departement",
}

export const ZonesSchema = z.object({
  id: z.coerce.number(),
  name: z.string(),
  level: z.nativeEnum(ZONE_LEVEL).nullable(),
  parent_id: z.coerce.number().nullish(),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
});
export interface ZonesDTO extends z.infer<typeof ZonesSchema> {}

export interface ZoneTreeDTO {
  id: number;
  name: string;
  level: ZONE_LEVEL;
  children: ZoneTreeDTO[];
}

export const ZoneTreeSchema = z.object({
  id: z.coerce.number().nullable(),
  name: z.string().nullish().optional(),
  level: z.nativeEnum(ZONE_LEVEL).nullish().optional(),
  children: z.lazy(() => ZoneTreeSchema).array(),
}) as z.ZodType<ZoneTreeDTO>;

export const ListSchedulesFiltersSchema = z.object({
  widgetIds: z.oneOrMore(z.string()),
  advertisementIds: z.oneOrMore(z.string()).optional(),
  from: z.dateString().or(z.date()).optional(),
  to: z.dateString().or(z.date()).optional(),
  ongoing: z
    .bool()
    .or(z.string().transform((s) => s === "true"))
    .optional(),
  enabled: z
    .bool()
    .or(z.string().transform((s) => s === "true"))
    .optional(),
  sort: z.enum(["asc", "desc"]).optional(),
});

export interface ListSchedulesFiltersDTO
  extends z.infer<typeof ListSchedulesFiltersSchema> {}

export const EventDatesSchema = z.object({
  id: z.coerce.number().int().positive(),
  event_id: z.coerce.number().int().positive(),
  from: z.dateString().or(z.date()),
  to: z.dateString().or(z.date()),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
});
export interface EventDatesDTO extends z.infer<typeof EventDatesSchema> {}

export const CoordinatesSchema = z.object({
  id: z.coerce.number(),
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  address: z.string().nullable(),
  city: z.string().nullish(),
  zone: z.coerce.number(),
  createdAt: z.dateString().or(z.date()),
  updatedAt: z.dateString().or(z.date()),
});
export interface CoordinatesDTO extends z.infer<typeof CoordinatesSchema> {}

export const FullAdvertisementBaseSchema = AdvertisementVersionSchema.merge(
  z.object({
    advertisement_version_id: z.string(),
    coordinates_id: z.coerce.number(),
    location_name: z.string(),
    archivedAt: z.dateString().or(z.date()).nullable(),
    coordinates: CoordinatesSchema,
    createdAt: z.dateString().or(z.date()),
    updatedAt: z.dateString().or(z.date()),
  }),
);
export interface FullAdvertisementBaseDTO
  extends z.infer<typeof FullAdvertisementBaseSchema> {}

export const FullEventAdvertisementSchema = FullAdvertisementBaseSchema.merge(
  z.object({
    type: z.literal(ADVERTISEMENT_TYPE.EVENT),
    event_id: z.coerce.number(),
    dates: EventDatesSchema.array(),
    categories: z
      .object({
        event_id: z.coerce.number().int().positive(),
        category_id: z.coerce.number().int().positive(),
        createdAt: z.dateString().or(z.date()),
      })
      .array(),
  }),
);
export interface FullEventAdvertisementDTO
  extends z.infer<typeof FullEventAdvertisementSchema> {}

export const FullRealEstateAdvertisementSchema =
  FullAdvertisementBaseSchema.merge(
    z.object({
      type: z.literal(ADVERTISEMENT_TYPE.REAL_ESTATE),
      real_estate_id: z.coerce.number(),
      surface: z.string(),
      rooms: z.string(),
      price: z.string(),
    }),
  );

export interface FullRealEstateAdvertisementDTO
  extends z.infer<typeof FullRealEstateAdvertisementSchema> {}

export const FullJobAdvertisementSchema = FullAdvertisementBaseSchema.merge(
  z.object({
    type: z.literal(ADVERTISEMENT_TYPE.JOB),
    job_id: z.coerce.number(),
    company_name: z.string(),
  }),
);
export interface FullJobAdvertisementDTO
  extends z.infer<typeof FullJobAdvertisementSchema> {}

export const FullAdvertisementSchema = z.discriminatedUnion("type", [
  FullEventAdvertisementSchema,
  FullRealEstateAdvertisementSchema,
  FullJobAdvertisementSchema,
]);

export type FullAdvertisementDTO = z.infer<typeof FullAdvertisementSchema>;

export const AdvertisementSummarySchema = z.object({
  id: z.string(),
  firstLine: z.string(),
  secondLine: z.string(),
  thirdLine: z.string(),
  previewLink: z.string(),
  originLink: z.string(),
  type: z.enum(["event", "immo", "emploi"]),
  metaData: z.any(),
  picture: z
    .string()
    .url()
    .or(
      z
        .string()
        .refine((s) => {
          try {
            new URL(s);
            return false;
          } catch (e) {
            return true;
          }
        })
        .transform((s) => `${BUCKET_URL}/${s}`),
    ),
});
export interface AdvertisementSummaryDTO
  extends z.infer<typeof AdvertisementSummarySchema> {}

export const CreateWidgetSchema = z.object({
  company_id: z.coerce.number().nullable().optional(),
  name: z.string().default("Nouveau widget"),
  source: z.string(),
  source_id: z.string(),
  rotation_active: z.bool().default(true),
  exposed: z.bool().default(true),
  include_owned_advertisements: z.bool().default(false),
  type: z.nativeEnum(WIDGET_TYPE).default(WIDGET_TYPE.CAROUSEL),
  customization: z.coerce.number().nullable().optional().default(null),
  advertisements_flux: ListAdvertisementsFiltersSchema.array().optional(),
});
export interface CreateWidgetDTO extends z.infer<typeof CreateWidgetSchema> {}

export const UpdateWidgetSchema = z
  .object({
    company_id: z.coerce.number().nullable(),
    name: z.string(),
    source: z.string(),
    source_id: z.string(),
    rotation_active: z.bool().or(z.string().transform((s) => s === "true")),
    exposed: z.bool(),
    include_owned_advertisements: z.bool(),
    type: z.nativeEnum(WIDGET_TYPE),
    customization: z.coerce.number().nullable(),
    advertisements_flux: z
      .json(ListAdvertisementsFiltersSchema.array())
      .nullish(),
  })
  .partial();
export interface UpdateWidgetDTO extends z.infer<typeof UpdateWidgetSchema> {}

export const CreateScheduleSchema = z.object({
  from: z.dateString().optional(),
  to: z.dateString().optional(),
  impression_goal: z.coerce.number().nullish().optional(),
  clic_goal: z.coerce.number().nullish().optional(),
  advertisement_version_id: z.string(),
  widget_id: z.string(),
  rotation_strategy: z.nativeEnum(ROTATION_STRATEGY),
});
export interface CreateScheduleDTO
  extends z.infer<typeof CreateScheduleSchema> {}

export const UpdateScheduleSchema = z
  .object({
    from: z.dateString().optional(),
    to: z.dateString().optional(),
    enabled: z.bool().default(true),
    order: z.coerce.number().default(0),
    impression_goal: z.coerce.number().nullish(),
    clic_goal: z.coerce.number().nullish(),
    rotation_strategy: z.nativeEnum(ROTATION_STRATEGY),
  })
  .partial();
export interface UpdateScheduleDTO
  extends z.infer<typeof UpdateScheduleSchema> {}

export const AdminUpdateCompanySchema = z.object({
  name: z.string().trim().optional(),
  eventEnabled: z.bool().optional(),
  immoEnabled: z.bool().optional(),
  jobEnabled: z.bool().optional(),
  apiEnabled: z.bool().optional(),
  trialEnd: z.dateString().optional(),
  subscription: z.nativeEnum(SUBSCRIPTION_TYPE).optional(),
  authorizedOrigins: z
    .oneOrMore(
      z
        .string()
        .url()
        .transform((s) => new URL(s).origin),
    )
    .optional(),
});
export interface AdminUpdateCompanyDTO
  extends z.infer<typeof AdminUpdateCompanySchema> {}

export const CreateCompanySchema = z.object({
  name: z.string().trim(),
  authorizedOrigins: z.oneOrMore(
    z
      .string()
      .url()
      .transform((s) => new URL(s).origin),
  ),
});
export interface CreateCompanyDTO extends z.infer<typeof CreateCompanySchema> {}

export const UpdateCompanySchema = AdminUpdateCompanySchema.pick({
  name: true,
  authorizedOrigins: true,
});
export interface UpdateCompanyDTO extends z.infer<typeof UpdateCompanySchema> {}

export const AdvertisementDraftBaseSchema = z.object({
  type: z.nativeEnum(ADVERTISEMENT_TYPE),
  title: z.string(),
  description: z.string(),
  url: z.string(),
  address: z.string(),
  location_name: z.string(),
  city: z.string().nullish().optional(),
  longitude: z.coerce.number(),
  latitude: z.coerce.number(),
  image: z.string().url(),
  schedules: z
    .object({
      widgetId: z.string(),
      from: z.dateString(),
      to: z.dateString(),
      impression_goal: z.coerce.number().nullish().optional(),
      clic_goal: z.coerce.number().nullish().optional(),
      sponsored: z.bool(),
    })
    .array(),
});
export const AdvertisementDraftJobSchema = z.object({
  company_name: z.string(),
  type: z.literal(ADVERTISEMENT_TYPE.JOB),
});
export const AdvertisementDraftEventSchema = z.object({
  dates: z
    .object({
      from: z.dateString(),
      to: z.dateString(),
    })
    .array(),
  categories: z.number().array().min(1),
  type: z.literal(ADVERTISEMENT_TYPE.EVENT),
});
export const AdvertisementDraftRealEstateSchema = z.object({
  surface: z.coerce.string(),
  price: z.coerce.string(),
  rooms: z.coerce.string(),
  type: z.literal(ADVERTISEMENT_TYPE.REAL_ESTATE),
});
export const AdvertisementDraftSchema = AdvertisementDraftBaseSchema.and(
  z.discriminatedUnion("type", [
    AdvertisementDraftJobSchema,
    AdvertisementDraftEventSchema,
    AdvertisementDraftRealEstateSchema,
  ]),
);
export const PartialAdvertisementDraftSchema =
  AdvertisementDraftBaseSchema.partial().and(
    z
      .object({
        company_name: z.string(),
        dates: z
          .object({
            from: z.dateString(),
            to: z.dateString(),
          })
          .array(),
        categories: z.number().array().min(1),
        surface: z.coerce.string(),
        price: z.coerce.string(),
        rooms: z.coerce.string(),
      })
      .partial(),
  );

export type AdvertisementDraftDTO = z.infer<typeof AdvertisementDraftSchema>;

export const UpdateAdvertisementBaseSchema = z
  .object({
    title: z.string().optional(),
    description: z.string().nullish().optional(),
    main_image: z.string().optional(),
    url: z.string().optional(),
    square_image: z.string().nullish().optional(),
    landscape_image: z.string().nullish().optional(),
    portrait_image: z.string().nullish().optional(),
    is_main_version: z.bool().optional(),
  })
  .partial();

export const UpdateJobAdvertisementSchema = z.object({
  type: z.literal(ADVERTISEMENT_TYPE.JOB),
  coordinates_id: z.number().optional(),
  location_name: z.string().optional(),
  company_name: z.string().optional(),
  archivedAt: z.dateString().or(z.date()).nullish().optional(),
});

export const UpdateRealEstateAdvertisementSchema = z.object({
  type: z.literal(ADVERTISEMENT_TYPE.REAL_ESTATE),
  coordinates_id: z.number().optional(),
  location_name: z.string().optional(),
  surface: z.number().positive().or(z.string()).optional(),
  rooms: z.number().int().positive().or(z.string()).optional(),
  price: z.number().positive().or(z.string()).optional(),
  archivedAt: z.dateString().or(z.date()).nullish().optional(),
});

export const UpdateEventAdvertisementSchema = z.object({
  type: z.literal(ADVERTISEMENT_TYPE.EVENT),
  coordinates_id: z.number().optional(),
  location_name: z.string().optional(),
  archivedAt: z
    .date()
    .or(z.dateString().transform((s) => new Date(s)))
    .nullish()
    .optional(),
});

export const UpdateAdvertisementSchema = z
  .discriminatedUnion("type", [
    UpdateJobAdvertisementSchema,
    UpdateRealEstateAdvertisementSchema,
    UpdateEventAdvertisementSchema,
  ])
  .and(UpdateAdvertisementBaseSchema);

export type UpdateAdvertisementDTO = z.infer<typeof UpdateAdvertisementSchema>;

export const PricingsSchema = z.object({
  name: z.string(),
  price: z.number(),
  subscriptionId: z.string().optional(),
  description: z.string().optional(),
  features: z
    .object({
      checked: z.boolean(),
      label: z.string(),
    })
    .array(),
  cta: z
    .object({
      label: z.string(),
      link: z.string().url(),
      external: z.boolean(),
    })
    .nullable()
    .optional(),
});
export interface PricingsDTO extends z.infer<typeof PricingsSchema> {}

const CSSSize = z.coerce.string();
const HexOrRGBColor = z.coerce.string();
const CSSFontWeight = z
  .number()
  .or(z.enum(["normal", "bold", "bolder", "light", "lighter"]));

const IconStyles = z
  .object({
    strokeWidth: CSSSize,
    color: HexOrRGBColor.optional(),
    strokeColor: HexOrRGBColor.optional(),
  })
  .partial();

const FontStyles = z
  .object({
    color: HexOrRGBColor,
    fontFamily: z.coerce.string(),
    fontSize: CSSSize,
    fontWeight: CSSFontWeight,
  })
  .partial();

export const CustomizationStylesSchema = z
  .object({
    arrows: IconStyles.merge(
      z.object({
        backgroundColor: HexOrRGBColor,
      }),
    ).partial(),
    card: z
      .object({
        global: z
          .object({
            radius: CSSSize,
            background: HexOrRGBColor,
            border: z.coerce.string(),
            padding: z.coerce.string(),
            shadow: z.coerce.string(),
          })
          .partial(),
        firstLine: FontStyles,
        secondLine: FontStyles,
        thirdLine: FontStyles,
        secondLineIcon: IconStyles,
        thirdLineIcon: IconStyles,
      })
      .partial(),
    gap: z.number(),
    background: HexOrRGBColor,
    padding: z.number(),
    pagination: z
      .object({
        primaryBackground: HexOrRGBColor,
        secondaryBackground: HexOrRGBColor,
        primaryColor: HexOrRGBColor,
        secondaryColor: HexOrRGBColor,
        fontFamily: z.coerce.string(),
        fontSize: CSSSize,
        fontWeight: CSSFontWeight,
        radius: CSSSize,
        padding: z.coerce.string(),
        gap: z.number(),
        border: z.coerce.string(),
      })
      .partial(),
    header: z
      .object({
        title: z.coerce.string(),
        accentColor: HexOrRGBColor,
      })
      .partial(),
    watermark: FontStyles,
  })
  .partial();

export const CustomizationContentSchema = z
  .object({
    version: z.literal("4.0.0"),
    styles: CustomizationStylesSchema,
    watermark: z.boolean().default(true),
    redirect: z.boolean().default(true),
    lines: z.number().optional(),
  })
  .partial();

export interface CustomizationContentDTO
  extends z.infer<typeof CustomizationContentSchema> {}

export const CustomizationSchema = z.object({
  id: z.coerce.number(),
  name: z.string(),
  content: z.json(CustomizationContentSchema),
  company_id: z.coerce.number(),
  createdAt: z.dateString(),
  updatedAt: z.dateString(),
});
export interface CustomizationDTO extends z.infer<typeof CustomizationSchema> {}

export const CreateEmptyCustomizationPayloadSchema = z.object({
  name: z.string().optional(),
  company_id: z.number(),
  content: CustomizationContentSchema,
});
export interface CreateEmptyCustomizationPayloadDTO
  extends z.infer<typeof CreateEmptyCustomizationPayloadSchema> {}

export const UpdateUserSchema = z.object({
  // email: z.string().email(),
  firstname: z.string().optional(),
  lastname: z.string().optional(),
});
export interface UpdateUserDTO extends z.infer<typeof UpdateUserSchema> {}

export const CreateInvitesSchema = z.object({
  email: z.string().email(),
  companyId: z.coerce.number().int().nullish().optional(),
  policyId: z.coerce.number().int().nullish().default(null),
});

export interface CreateInvitesDTO extends z.infer<typeof CreateInvitesSchema> {}

export enum INVITE_STATUS {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  EXPIRED = "expired",
  REMOVED = "removed",
}

export const InvitesSchema = z.object({
  id: z.number(),
  email: z.string().email(),
  status: z.nativeEnum(INVITE_STATUS),
  companyId: z.number(),
  policyId: z.number(),
  inviterEmail: z.string().email(),
  inviterId: z.number().nullable(),
  userId: z.number().nullable(),
  createdAt: z.dateString(),
  updatedAt: z.dateString(),
});
export interface InvitesDTO extends z.infer<typeof InvitesSchema> {}

export const CleanInvitesSchema = InvitesSchema.omit({
  status: true,
}).and(
  z.object({
    company: CompaniesSchema.omit({ subscription: true }),
    inviter: SafeUsersSchema.pick({
      id: true,
      email: true,
      policyId: true,
    }).nullish(),
  }),
);

export interface CleanInvitesDTO extends z.infer<typeof CleanInvitesSchema> {}

export const InvalidTimeRangeSchema = z.object({
  error: z.literal("InvalidTimeRange"),
  dates: z.unknown(),
  message: z.string(),
});
export interface InvalidTimeRangeDTO
  extends z.infer<typeof InvalidTimeRangeSchema> {}

export const ListCompaniesSchema = z.object({
  ids: z.oneOrMore(z.number()).optional(),
  query: z.string().min(2).optional(),
  subscription: z.nativeEnum(SUBSCRIPTION_TYPE).optional(),
});
export interface ListCompaniesDTO extends z.infer<typeof ListCompaniesSchema> {}

export const ListWidgetsSchema = z.object({
  company_id: z.coerce.number().optional(),
  rotation_active: z
    .boolean()
    .or(z.string().transform((s) => s === "true"))
    .optional(),
  exposed: z
    .boolean()
    .or(z.string().transform((s) => s === "true"))
    .optional(),
  source: z.oneOrMore(z.string()).optional(),
  source_id: z.oneOrMore(z.string()).optional(),
  public_key: z.oneOrMore(z.string()).optional(),
  name: z.oneOrMore(z.string()).optional(),
  id: z.oneOrMore(z.string()).optional(),
});
export interface ListWidgetsDTO extends z.infer<typeof ListWidgetsSchema> {}

export const ListUsersSchema = z.object({
  companyId: z.coerce.number().optional(),
  ids: z.oneOrMore(z.coerce.number()).optional(),
  email: z.string().optional(),
  query: z
    .string()
    .transform((s) => (s.length < 2 ? undefined : s))
    .optional(),
});
export interface ListUsersDTO extends z.infer<typeof ListUsersSchema> {}

export const MutationLogSchema = z.object({
  id: z.number(),
  type: z.string(),
  resource: z.string().nullish(),
  user_id: z.number().nullish(),
  user_name: z.string().nullish(),
  payload: z.string().nullish(),
  createdAt: z.dateString(),
  updatedAt: z.dateString(),
});
export interface MutationLogDTO extends z.infer<typeof MutationLogSchema> {}

export const ListMutationLogSchema = z
  .object({
    from: z.dateString().or(z.date()).optional(),
    to: z.dateString().or(z.date()).optional(),
  })
  .and(paginationSchema(200));
export interface ListMutationLogDTO
  extends z.infer<typeof ListMutationLogSchema> {}

export const ApiKeysSchema = z.object({
  id: z.string(),
  companyId: z.number(),
  name: z.string().nullish(),
  apiKey: z.string().nullish().optional(),
  isActive: z.boolean(),
  lastUsage: z.dateString().nullish(),
  createdAt: z.dateString(),
  updatedAt: z.dateString(),
});
export interface ApiKeysDTO extends z.infer<typeof ApiKeysSchema> {}

export const CreateApiKeysSchema = z.object({
  companyId: z.coerce.number().optional(),
  name: z.string().nullish(),
});
export interface CreateApiKeysDTO extends z.infer<typeof CreateApiKeysSchema> {}

export const UpdateApiKeysSchema = z.object({
  name: z.string().optional(),
  isActive: z.bool().optional(),
});
export interface UpdateApiKeysDTO extends z.infer<typeof UpdateApiKeysSchema> {}
