import * as Sentry from "@sentry/react";
import {
  extraErrorDataIntegration,
  captureConsoleIntegration,
  httpClientIntegration,
} from "@sentry/integrations";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { PlanUpgradeError } from "@/api/authorization";

Sentry.init({
  dsn: "https://2cf4aa34565b53916c1ebb8668d5e68e@o4506060440010752.ingest.sentry.io/4506060442763264",
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/(.*\.)?timenjoy\.fr/,
        /^https:\/\/(.*\.)?konnectz\.io/,
      ],
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.dedupeIntegration(),
    captureConsoleIntegration({
      levels: ["error"],
    }),
    extraErrorDataIntegration(),
    httpClientIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [],
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  sendDefaultPii: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
  enabled: import.meta.env.PROD,
  ignoreErrors: [PlanUpgradeError.name],
});
