import { createApiKeys, deleteApiKeys, updateApiKeys } from "@/api/api_keys";
import { CreateApiKeysSchema, UpdateApiKeysSchema } from "@/api/definitions";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "@/lib/zod";

export interface IApiSettingsActionData {}
export async function ApiSettingsAction({
  request,
}: ActionFunctionArgs): Promise<IApiSettingsActionData> {
  const formData = await request.formData();
  if (request.method === "POST") {
    const apiKey = await withToast(
      () => {
        const payload = CreateApiKeysSchema.parse(objectFromFormData(formData));
        return createApiKeys(payload);
      },
      {
        success: `Clé d'api créé avec succès`,
        loading: `Création en cours`,
        error: `Échec de la création`,
      },
    );
    return apiKey ?? {};
  }
  if (request.method === "PUT") {
    await withToast(() => {
      const { id, ...payload } = UpdateApiKeysSchema.and(
        z.object({ id: z.string() }),
      ).parse(objectFromFormData(formData));
      return updateApiKeys(id, payload);
    });
  }
  if (request.method === "DELETE") {
    await withToast(() => {
      const { id } = z
        .object({ id: z.string() })
        .parse(objectFromFormData(formData));
      return deleteApiKeys(id);
    });
  }
  return {};
}
