import { TableHead } from "@/components/ui/table";
import { ColumnsDef } from "@/hooks/useColumns";

export type CompaniesTableColumnName =
  | "id"
  | "logo"
  | "name"
  | "types"
  | "subscription"
  | "widgets"
  | "users"
  | "created_at";

export const CompaniesTableColumns: ColumnsDef<CompaniesTableColumnName> = {
  id: {
    value: "id",
    label: "Id",
    header: () => <TableHead className="w-20">#ID</TableHead>,
  },
  logo: {
    value: "logo",
    label: "Logo",
    header: () => (
      <TableHead className="w-20 first:w-24 first:px-4 px-0">Logo</TableHead>
    ),
  },
  name: {
    value: "name",
    label: "Nom",
    header: () => <TableHead className="w-full max-w-[256px]">Nom</TableHead>,
  },
  types: {
    value: "types",
    label: "Type d'annonce",
    header: () => <TableHead>Type d'annonce</TableHead>,
  },
  subscription: {
    value: "subscription",
    label: "Abonnement",
    header: () => <TableHead>Abonnement</TableHead>,
  },
  widgets: {
    value: "widgets",
    label: "Widgets",
    header: () => <TableHead>Widgets</TableHead>,
  },
  users: {
    value: "users",
    label: "Utilisateurs",
    header: () => <TableHead>Utilisateurs</TableHead>,
  },
  created_at: {
    value: "created_at",
    label: "Date de création",
    header: () => <TableHead className="w-max">Date de création</TableHead>,
  },
} as const;
