import {
  aggregateTimeSerie,
  getAggregationPeriodForDates,
  getWidgetStats,
} from "@/api/analytics";
import {
  FullAdvertisementDTO,
  WidgetAnalyticsFiltersSchema,
  WidgetAnalyticsSummaryDTO,
} from "@/api/definitions";
import { LoaderFunctionArgs, defer, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { listAdvertisements } from "@/api/advertisements";
import { getCurrentUser } from "@/api/authentication";
import { dependsOn } from "@/lib/promise";
import { extractEnabledAdvertisementTypes } from "@/api/companies";
import { omit, pick } from "@/lib/pick";
import { withId } from "@/lib/array";
import { canSeeAnalytics } from "@/api/authorization";

export interface IWidgetAnalyticsLoaderData {
  analytics: Promise<WidgetAnalyticsSummaryDTO>;
  advertisementAnalytics: Promise<
    (FullAdvertisementDTO & { impressions: number; clics: number })[]
  >;
}

export async function WidgetAnalyticsLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const session = getCurrentUser();
  if (!params.widgetId) throw new Error("No widget selected");
  const url = new URL(request.url);
  const query = z
    .searchParams(WidgetAnalyticsFiltersSchema, "safeParse")
    .parse(url.searchParams);
  const filters = query.success
    ? query.data
    : WidgetAnalyticsFiltersSchema.parse({});
  try {
    await canSeeAnalytics();
  } catch (e) {
    return defer({
      analytics: Promise.resolve({
        period: [new Date(), new Date()],
        timeSerie: [],
        impression_per_origin: [],
        advertisements: [],
        compute_time: 0,
      }),
      advertisementAnalytics: Promise.resolve([]),
    } satisfies IWidgetAnalyticsLoaderData);
  }
  const analytics = getWidgetStats(params.widgetId, filters, request.signal)
    .then(dependsOn(session))
    .then((r) => ({
      ...r,
      timeSerie: aggregateTimeSerie(
        getAggregationPeriodForDates(filters.from, filters.to),
        r.timeSerie,
        [filters.from, filters.to],
      ),
    }));
  const advertisementAnalytics = analytics.then(async (res) => {
    const company = (await session)!.company!;
    const stats = res.advertisements;
    const ids = stats.map((row) => row.advertisement) as [string, ...string[]];
    if (!ids[0]) return [];
    const advertisementsResponses = await Promise.all(
      extractEnabledAdvertisementTypes(company).map((type) =>
        listAdvertisements({
          type,
          ids,
          all_visible: true,
          company_id: company.id,
        }),
      ),
    );
    const advertisements = advertisementsResponses.map((r) => r.results).flat();
    return stats
      .map((stat) => ({
        ...omit(stat, "advertisement"),
        ...advertisements.find(withId(stat.advertisement))!,
      }))
      .filter((s) => s.title);
  });
  return defer({
    analytics,
    advertisementAnalytics,
  } satisfies IWidgetAnalyticsLoaderData);
}
