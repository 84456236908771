import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import {
  CalendarDays,
  Edit,
  ExternalLink,
  EyeOff,
  FileText,
  Hash,
  Pause,
  Play,
  Trash,
} from "lucide-react";
import { ApiKeysDTO, FullAdvertisementDTO } from "@/api/definitions";
import { cn } from "@/lib/utils";
import { ActionsMenuContent, ActionsMenuItem } from "@/components/actions-menu";
import { useCreateScheduleModal } from "@/modals/create-schedule";
import { useFetcher, useNavigate } from "react-router-dom";
import { useCreateBlacklistModal } from "@/modals/create-blacklist";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { copyText } from "@/lib/copy";
import { Separator } from "@/components/ui/separator";
import { objectToFormData } from "@/lib/query";
import { useDeleteApiKeyModal } from "@/modals/delete-api-key";

export interface IApiKeysActionsProps extends ApiKeysDTO {}

export default function ApiKeysActions({ id }: IApiKeysActionsProps) {
  const deleteApiKeyModal = useDeleteApiKeyModal();
  return (
    <ActionsMenuContent className="w-56" align="start" side="left">
      {/* <ActionsMenuItem className={cn("cursor-pointer")}>
        <Edit className="mr-2 h-4 w-4" />
        <span>Renommer</span>
      </ActionsMenuItem> */}
      <Separator />
      <ActionsMenuItem
        className={cn(
          "cursor-pointer bg-destructive text-destructive-foreground",
        )}
        onClick={() => deleteApiKeyModal.openModal({ id })}
      >
        <Trash className="mr-2 h-4 w-4" />
        <span>Supprimer</span>
      </ActionsMenuItem>
    </ActionsMenuContent>
  );
}
