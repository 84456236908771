import * as React from "react";
import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Form } from "react-router-dom";
import { Button } from "@/components/ui/button";
import FormState from "@/components/form-state";

export interface IDeleteApiKeyModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
  id: string;
}

const DESIRED_INPUT_VALUE = "supprimer ma clé api";
export function DeleteApiKeyModal({ closeModal, id }: IDeleteApiKeyModalProps) {
  const [inputValue, setInputValue] = React.useState("");
  return (
    <Dialog open onOpenChange={() => closeModal?.()}>
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Supprimer ma clé d'api</DialogTitle>
          <DialogDescription>
            En supprimant la clé d'api, elle ne sera plus reconnue par l'api et
            ne permettra plus d'autoriser vos requêtes.
          </DialogDescription>
          <DialogDescription>
            L'opération prendra effet immédiatement.
          </DialogDescription>
        </DialogHeader>
        <Form action="/app/settings/api?index" method="PUT">
          <div className="space-y-4 w-full">
            <p className="px-4 py-2 bg-destructive/40 text-[#ff565f] text-sm leading-6 tracking-wide rounded-md">
              <b>Attention</b> : Cette action est irréversible.
            </p>
            <div className="space-y-2">
              <Label>
                Saisissez <b>{DESIRED_INPUT_VALUE}</b> ci-dessous:
              </Label>
              <Input
                name="name"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            <DialogFooter className="sm:justify-between">
              <Button
                type="submit"
                variant="destructive"
                disabled={inputValue !== DESIRED_INPUT_VALUE}
              >
                Confirmer
              </Button>
              <Button
                type="button"
                className="min-w-[128px]"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeModal?.();
                }}
              >
                Annuler
              </Button>
            </DialogFooter>
            <FormState data={{ id, isActive: false }} />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const [useDeleteApiKeyModal, DeleteApiKeyProvider, DeleteApiKeyContext] =
  createModalContext(DeleteApiKeyModal);
