import {
  aggregateTimeSerie,
  getAdvertisementStats,
  getAggregationPeriodForDates,
} from "@/api/analytics";
import { AdvertisementAnalyticsFiltersSchema } from "@/api/definitions";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { listOwnedWidgets } from "@/api/widgets";
import { withId } from "@/lib/array";
import { canSeeAnalytics, PlanUpgradeError } from "@/api/authorization";

export interface IAdvertisementAnalyticsLoaderData {
  analytics: ReturnType<typeof getAdvertisementStats>;
  widgets: ReturnType<typeof listOwnedWidgets>;
}

export async function AdvertisementAnalyticsLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  if (!params.advertisementId) throw new Error("No advertisement selected");
  const url = new URL(request.url);
  const query = z
    .searchParams(AdvertisementAnalyticsFiltersSchema, "safeParse")
    .parse(url.searchParams);
  const filters = query.success
    ? query.data
    : AdvertisementAnalyticsFiltersSchema.parse({});
  const widgets = listOwnedWidgets(request.signal);
  return defer({
    widgets,
    analytics: widgets
      .then(async (wis) => {
        if (!filters.widgetId?.length)
          filters.widgetId = wis.results.map((w) => w.id);
        filters.widgetId = filters.widgetId.filter((w) =>
          wis.results.find(withId(w)),
        );
        await canSeeAnalytics();
        return getAdvertisementStats(
          params.advertisementId!,
          filters,
          request.signal,
        );
      })
      .then((r) => ({
        ...r,
        data: aggregateTimeSerie(
          getAggregationPeriodForDates(filters.from, filters.to),
          r.data,
          [filters.from, filters.to],
        ),
      }))
      .catch((err) => {
        if (err instanceof PlanUpgradeError) {
          return {
            data: [],
            period: [new Date(), new Date()],
            compute_time: 0,
          };
        }
        throw err;
      }),
  } satisfies IAdvertisementAnalyticsLoaderData);
}
